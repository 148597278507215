.user-account-container {
  @apply grid;

  .form-container {
    @apply grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4;
  }

  .account-title {
    .header-title {
      font-size: 22px;
      @apply font-bold
    }
  }

  .user-account-card {
    @apply pt-6 pb-6
  }
}
